<template>
    <div class="container-fluid">
        
		<div class="topfilter pt-4">
			<div class="col-12 my-auto title">
				{{ $t('Integrations') }}
			</div>
		</div>
		
		<!-- Settings: {{ $route.params.page }} -->
		<div class="custom-tabs setting-tabs integration">
			<b-tabs content-class="mt-3">
				
				<b-tab @click="changeRoute('esp')" :active="($route.params.page == 'esp') ? true : false" title="ESP">
					<esp :favoriteEsp="favoriteEsp"></esp>
				</b-tab>
				
				<b-tab @click="changeRoute('crms')" :active="($route.params.page == 'crms') ? true : false" title="CRM">
					<crms :favoriteCrm="favoriteCrm"></crms>
				</b-tab>
				
				<!--b-tab @click="changeRoute('voip')" :active="($route.params.page == 'voip') ? true : false" title="VOIP">
					<voip :favoriteVoip="favoriteVoip"></voip>
				</b-tab-->
				
				<!--b-tab @click="changeRoute('leadenrichment')" :active="($route.params.page == 'leadenrichment') ? true : false" title="Lead Enrichment">
					<lead-enrichment :leadEnrichmens="leadEnrichmens"></lead-enrichment>
				</b-tab-->
			</b-tabs>
		</div>
		
    </div>
</template>
<script>
	import crms 	from './integrations/crms'
	import esp  	from './integrations/esp'
	// import voip 	from './integrations/voip'
	// import leadEnrichment	from './integrations/leadenrichment'
	
	import Pusher 		from "pusher-js"
	
	export default {
		data() {
			return {
				//
				favoriteCrm		: {},
				favoriteVoip	: {},
				leadEnrichmens	: {},
				favoriteEsp		: {},
			}
		},
		methods: {
			
			changeRoute( element ) {
				this.$store.state.selectedPage = element
				this.$router.push({path:'/integration/' + element})
			},
			
			GetIntegrations() {
				
				let that = this
				
				let url   = 'get_integrations'
				let method= 'GET'
				let data  = {}
				
				that.$store.dispatch('apiRequest', { url, method, data } ).then((response) => {
					
					that.favoriteCrm	= response.data.integrations['CRMs']
					that.favoriteVoip	= response.data.integrations['VOIP']
					that.leadEnrichmens	= response.data.integrations['Lead Enrichment']
					that.favoriteEsp	= response.data.integrations['ESPs']
					
				}).catch((  ) => { // {response}
					
				})
				
			},
			
		},
		components: {
			crms,
			esp,
			// voip,
			// leadEnrichment,
		},
		mounted() {
			let that = this
			that.GetIntegrations()
			
			var pusher 	= new Pusher(process.env.VUE_APP_PUSHER_APP_KEY, {
				cluster	: process.env.VUE_APP_PUSHER_APP_CLUSTER,
				forceTLS: true
			});
			var channel = pusher.subscribe('zapier_output' + JSON.parse(localStorage.getItem('oml-client')).id + '_src');
            channel.bind('zapier_output_src', function(data) {
                if( data.success ) {
					that.notifyMe('<div id="zapier-output">Success<input id="output-source" type="hidden" value="' + data.zapier_output + '" /></div>', 'success')
				}
            });
            
		},
	}
</script>
<style lang="scss">
	
	.integration {
		
		.new-sources-md {
			button {
				height: 40px;
				font-size: 14px;
				width: 100px;
				border-radius: 4px;
				background-color:#00bbf6;
				border:1px solid #00bbf6;
				font-size:14px;
				font-weight:400;
				color:#fff;
				line-height: 1.5;
				
				&:hover{
					background-color:#01a3d6;
				}
			}
		}
		
	}
	
	.alert-oml {
		font-size: 12px;
		width: 80%;
		margin: 0;
		background-color: #00bbf629;
		border-color: #fff;
		color: #0c5460;
		padding: 10px 10px;
		
		a {
			font-weight: 550;
			text-decoration: underline !important;
			color: #0a75a7 !important;
		}
		
		img {
			padding-right: 0.5rem !important;
		}
		
	}
</style>