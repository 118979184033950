<template>
    <div class="container">
        <div class="card card-default">
            <div class="card-header">{{$t('Dashboard')}}</div>
            <div class="card-body">
                {{$t('Welcome')}}
            </div>
        </div>
    </div>
</template>
<script>
  export default {
    data() {
      return {
        //
      }
    },
    components: {
      //
    },
	mounted() {
		// console.log(this.$auth)
	},
	
  }
</script>