<template>
    <div class="container-fluid">
		<div class="topfilter pt-4">
			<div class="col-12 my-auto title">
				{{ $t('Settings') }}
				<p class="text-secondary" style="font-size: 18px;">
					<span>{{ workspaceName }}</span>
					
					<b-dropdown size="sm"  variant="link" toggle-class="text-decoration-none" right no-caret class="p-0" v-if="ClientSelected.first_client != ClientSelected.id">
						<template v-slot:button-content>
							<i style="font-size: 18px;" class="fa fa-ellipsis-v dropdown-icon text-secondary"></i>
						</template>
						
						<b-dropdown-item v-if="$auth.user().id == ClientSelected.owner_id" @click="ActionElement('Delete')">
							<i class="fa fa-trash pr-1"></i> {{ $t('DeleteWorkspace') }}
						</b-dropdown-item>
						<b-dropdown-item v-else @click="ActionElement('Leave')">
							<i class="fa fa-trash pr-1"></i> {{ $t('LeaveWorkspace') }}
						</b-dropdown-item>
					</b-dropdown>
					
				</p>
			</div>
		</div>
		
		<!-- Settings: {{ $route.params.page }} -->
		<div class="custom-tabs setting-tabs">
			<b-tabs content-class="mt-3">
				<!--b-tab title="Profile" @click="changeRoute('profile')" :active="($route.params.page == 'profile' || typeof $route.params.page == 'undefined') ? true : false">
					<profile-user></profile-user>
				</b-tab-->
				<!--b-tab title="Plan" @click="changeRoute('plan')" :active="($route.params.page == 'plan') ? true : false" id="Plan-hide">
					<plan></plan>
				</b-tab-->
				
				<b-tab @click="changeRoute('clients')" :active="($route.params.page == 'clients') ? true : false" :title="$t('Workspace')">
					<!--template v-slot:title>
						Client Settings
						<span content="You can manage multiples clients with total separation of leads and access rights. Make sur to choose the right client before proceeding to managing your leads." info-tooltip="hover me">
							<i class="fa fa-info-circle text-primary"></i>
						</span>
					</template-->
					<div class="w-90">
						<client-settings></client-settings>
					</div>
				</b-tab>
				
				<b-tab v-if="!$store.state.active || ($store.state.active && 'list status' in $store.state.permissions)" :title="$t('LeadStatus')" @click="changeRoute('leadstatus')" :active="($route.params.page == 'leadstatus') ? true : false" id="lead-status">
					<div class="w-90"><lead-status></lead-status></div>
				</b-tab>
				
				<b-tab v-if="!$store.state.active || ($store.state.active && 'list users' in $store.state.permissions)" :title="$t('Members2')" @click="changeRoute('team')" :active="($route.params.page == 'team') ? true : false">
					<div class="w-90"><team-members></team-members></div>
				</b-tab>
				
				<b-tab @click="changeRoute('email-templates')" :active="($route.params.page == 'email-templates') ? true : false" v-if="!$store.state.active || ($store.state.active && 'send email to leads' in $store.state.permissions)">
				<template #title>
					<div class="d-flex align-items-center justify-content-center">
						{{$t('SMS_Email_Templates')}}<span class="oml-countTemp-title">{{countTemp}}</span>
					</div>
				</template>
					<div class="w-90"><email-templates @countTemplates="ChangeCountTemp($event)"></email-templates></div>
				</b-tab>
				
				<b-tab :title="$t('FacebookToken')" @click="changeRoute('fbtoken')" :active="($route.params.page == 'fbtoken') ? true : false">
					<div class="w-90"><fb-token></fb-token></div>
				</b-tab>

				<!--b-tab title="Integration" @click="changeRoute('integartion')" :active="($route.params.page == 'integartion') ? true : false">
					<div class="w-90"><integration></integration></div>
				</b-tab!-->
				
				<!--b-tab title="White label" @click="changeRoute('whitelabel')" :active="($route.params.page == 'whitelabel') ? true : false">
					<white-label></white-label>
				</b-tab-->
				
				<!--b-tab title="Email templates" :active="($route.params.page == 'emailTemplates') ? true : false">
					<email-templates></email-templates>
				</b-tab-->
				<!--b-tab title="Scoring" :active="($route.params.page == 'scoring') ? true : false">
					<scoring></scoring>
				</b-tab-->
				<!--b-tab title="Dispatch" :active="($route.params.page == 'dispatch') ? true : false">
					<dispatch></dispatch>
				</b-tab-->
				<!--b-tab title="Clients" @click="changeRoute('clients')" :active="($route.params.page == 'clients') ? true : false">
					<list-clients></list-clients>
				</b-tab-->
			</b-tabs>
		</div>
		
		
		<b-modal id="modal-delete" centered hide-footer title="Please Confirm">
			<div>
				<div class="row">
					<div class="col-12 mb-1">
						<p class="m-0" style=" color: #000; font-size: 14px; ">{{ $t('ConfrmDelete', {TypeAction:typeAction}) }}</p>
						<b>{{ ClientSelected.name }}</b>
					</div>
				</div>
				<div class="row">
					<div class="col-12 text-right">
						<b-button size="sm" variant="success" @click="SaveEvent()">
							<i class="fa fa-spinner fa-spin" v-if="LoadingAction"></i> {{ $t('Yes') }}
						</b-button>
					</div>
				</div>
			</div>
		</b-modal>


		<b-modal id="osix" size="lg" hide-footer title="Connect your Osix">
			<div class="form-row m-0 integrationModal">
				<div class="form-group col-md-4 pl-0">
                    <label for="name">Srnario ID</label>
                    <input type="text" v-model="senarioId_o" class="form-control" placeholder="Sénario ID..." />
				</div>

                <div class="form-group col-md-4 pl-0">
                    <label for="name">URL</label>
                    <input type="text" v-model="url_o" class="form-control" placeholder="URL..." />
				</div>

				<div class="form-group col-md-4 pl-0">
                    <label for="name">SDA</label>
					<input type="number" v-model="sda_o" class="form-control" placeholder="SDA..." />
				</div>
				<div class="form-group col-md-4 pl-0">
                    <label for="name">Interval</label>
                    <input type="number" v-model="interval_o" class="form-control" placeholder="Interval..." />
				</div>
				<div class="form-group col-md-4 pl-0">
                    <label for="name">Tries</label>
                    <input type="number" v-model="tries_o" class="form-control" placeholder="Tries..." />
				</div>
				<div class="form-group col-md-4 pl-0">
                    <label for="name">Qualified status</label>
                    <select class="form-control" v-model="qualified_status_o">
                        <option value=""></option>
                        <option v-for="(element, key) in myArrayStatus" :key="`key-${key}`" :value="element.id">{{ element.status}}</option>
                      
                    </select>
				</div>
				<div class="form-group col-md-4 pl-0">
                    <label for="name">Unqualified status</label>
                    <select class="form-control" v-model="unqualified_status_o">
                        <option value=""></option>
                        <option v-for="(element, key) in myArrayStatus" :key="`key-${key}`" :value="element.id">{{ element.status}}</option>
                      
                    </select>
				</div>
				<div class="form-group col-md-4 pl-0">
                    <label for="name">Call back status</label>
                    <select class="form-control" v-model="call_back_status_o"> 
                        <option value=""></option>
                        <option v-for="(element, key) in myArrayStatus" :key="`key-${key}`" :value="element.id">{{ element.status}}</option>
                      
                    </select>
				</div>

				<div class="form-group col-md-4 pl-0">
                    <label for="name">Unreachable status</label>
                    <select class="form-control" v-model="unreachable_status_o"> 
                        <option value=""></option>
                        <option v-for="(element, key) in myArrayStatus" :key="`key-${key}`" :value="element.id">{{ element.status}}</option>
                      
                    </select>
				</div>

				<div class="form-group col-md-4 pl-0">
                    <label for="name">Archived status</label>
                    <select class="form-control" v-model="archived_status_o"> 
                        <option value=""></option>
                        <option v-for="(element, key) in myArrayStatus" :key="`key-${key}`" :value="element.id">{{ element.status}}</option>
                      
                    </select>
				</div>

				<div class="form-group col-md-4 pl-0">
                    <label for="name">Accept</label>
                    <div class="inputNumbers">
						<multiselect 
							v-model="accept_o"  
							:options="options" 
							:multiple="true" 
							select-label=""
							deselect-label=""
						></multiselect>
					</div>
				</div>

				<div class="form-group col-md-4 pl-0">
                    <label for="name">Refuse</label>
                    <div class="inputNumbers">
						<multiselect 
							v-model="refuse_o"  
							:options="options" 
							:multiple="true" 
							select-label=""
							deselect-label=""
						></multiselect>
					</div>
				</div>
               
				<div class="form-group col-md-12 pl-0 mt-3">
					<div class="modal-actions">
						<button class="oml-btn outlined gray-btn mr-3" @click="$bvModal.hide('osix');" >{{$t('Cancel')}}</button>
						<button class="oml-btn" @click="saveSettingsOsix()" >{{ $t('Save') }}</button>
					</div>
				</div>
			</div>
		</b-modal>

		
    </div>
</template>
<script>
	// import profileUser from './settings/profile'
	// import emailTemplates from './settings/emailTemplates'
	// import ListClients from './settings/ListClients'
	// import scoring	   from './settings/scoring'
	//import dispatch	   from './settings/dispatch'
	
	// import plan		   from './settings/plan'
	import teamMembers 	from './settings/teamMembers'
	import ClientSettings from './settings/ClientSettings'
	import LeadStatus  	from './settings/LeadStatus'
	import FbToken	  	from './settings/FbToken'
	//import integration	  	from './settings/integration'
	// import WhiteLabel	from './settings/WhiteLabel'
	import emailTemplates from './settings/emailTemplate'

	import Multiselect from 'vue-multiselect'
	
	export default {
		data() {
			return {
				//
				workspaceName : '',
				LoadingAction : false,
				ClientSelected: {},
				typeAction	  : '',
				countTemp:0,
				senarioId_o:0,
				url_o: '',
				sda_o: '',
				interval_o: 0,
				tries_o:0,
				accept_o:[],
				refuse_o:[],
								
				qualified_status_o: 0,
				unqualified_status_o: 0,
				call_back_status_o: 0,
				unreachable_status_o: 0,
				archived_status_o: 0,
				myArrayStatus :[],
				value: null,
				options: [0,1,2,3,4,5,6,7,8,9]
			}
		},
		methods: {
			
			GetStatusInfo() {
				let that = this
				
				let url   = 'get_user_info'
				let method= 'GET'
				let data  = {}
				that.$store.dispatch( 'apiRequest', { url, method, data } ).then((response) => {
					
					that.myArrayStatus = []
					response.data.status.forEach( function(entry) {
						that.myArrayStatus.push({
							id		: entry.id,
							status	: entry.state
						})
						
					} );
				}).catch(( {response} ) => {
					console.log(response)
				})
				
			},
			saveSettingsOsix(){
				let url = '/communication/osix/setting'; 
									let method= 'POST';
									let data = {
											"senarioId"  : this.senarioId_o,
											"url" : this.url_o,
											"sda" : this.sda_o,
											"interval": this.interval_o,
											"tries":this.tries_o,
											"button_pressed" : {"accept":this.accept_o, "refuse": this.refuse_o},
											"qualified_status": this.qualified_status_o,
											"unqualified_status": this.unqualified_status_o ,
											"call_back_status":this.call_back_status_o,
											"unreachable_status":this.unreachable_status_o,
											"archived_status":this.archived_status_o
									};
									this.$store.dispatch('apiRequest', { url, method, data } ).then(() => {
									this.$bvModal.hide("osix");
									this.notifyMe(this.$t("ChangesSaved"), 'success')

								}).catch(( {response} ) => {
									this.notifyMe(response.data.message, 'warn')
								})
			},
			getSettingsOsix(){
				let url = '/communication/osix/setting/get'; 
									let method= 'GET';
									let data = {};
									this.$store.dispatch('apiRequest', { url, method, data } ).then((response) => {
									this.senarioId_o=response.data.settings.senarioId;
									this.url_o= response.data.settings.url;
									this.sda_o= response.data.settings.sda;
									this.interval_o= response.data.settings.interval;
									this.tries_o=response.data.settings.tries;

									let acceptArray = response.data.settings.button_pressed.accept
									this.accept_o = acceptArray

									let refuseArray = response.data.settings.button_pressed.refuse
									this.refuse_o = refuseArray
													
									this.qualified_status_o= response.data.settings.qualified_status;
									this.unqualified_status_o= response.data.settings.unqualified_status;
									this.call_back_status_o= response.data.settings.call_back_status;
									this.unreachable_status_o= response.data.settings.unreachable_status;
									this.archived_status_o= response.data.settings.archived_status;
								}).catch(( {response} ) => {
									console.log(response)
								})
			},
			notifyMe(message, variant) {
				this.$notify({ group	: 'foo', text	: message, type	: variant });
			},
			
			changeRoute( element ) {
				this.$store.state.selectedPage = element
				this.$router.push({path:'/settings/' + element})
			},
			
			ActionElement( action ) {
				let that = this
				that.typeAction = action
				that.$bvModal.show('modal-delete')
			},
			
			SaveEvent() {
				let that = this
				
				that.LoadingAction = true
				let url   = '/client/removeclient'
				let method= 'POST'
				let data  = { id:that.ClientSelected.id }
				that.$store.dispatch('apiRequest', { url, method, data } ).then(() => {
					that.notifyMe('Your workspace has been deleted successfully', 'success')
					that.$bvModal.hide('modal-delete');
					that.LoadingAction = false
					
					let clients   = JSON.parse ( localStorage.getItem('oml-clients') )
					clients.forEach( function(client) {
						if( client.id != that.ClientSelected.id ) {
							localStorage.setItem('oml-client', JSON.stringify( client ) )
						}
					} )
					
					location.reload()
				}).catch(( {response} ) => {
					that.notifyMe(response.data.message, 'warn')
					that.LoadingAction = false
				})
				
			},
			ChangeCountTemp(count)
			{
				this.countTemp=count;
			},
		},
		components: {
			// plan,
			teamMembers,
			ClientSettings,
			LeadStatus,
			FbToken,
			emailTemplates,
			Multiselect,
			//integration,
			// WhiteLabel
			//
			// profileUser,
			// emailTemplates,
			// scoring,
			// ListClients,
			//dispatch
		},
		mounted() {
			
			let ClientSelected 	= JSON.parse( localStorage.getItem('oml-client') ) 
			this.ClientSelected = ClientSelected
			this.workspaceName 	= ClientSelected.name
			this.GetStatusInfo();
			this.getSettingsOsix();
			// this.getClients()

			this.$store.dispatch('GetClients')
			//osix
			if (this.$route.params.page == 'osix'){
					
					this.$bvModal.show('osix');
			}
			// console.log(this.ClientSelected)
		},
	}
</script>
<style lang="scss">
	
	
	.setting-tabs {
		[info-tooltip]:before {
			min-width: 30em;
		}
		
		.nav-tabs {
			width: 70%;
		}
	}
	
	.custom-tabs {
		
		.title-setting {
			color: #313234;
			font-weight: 700;
		}
		.setting-top-title {
			padding: 50px 0 20px;
		}
		.nav-tabs {
			justify-content: center;
			margin: auto;
			
			.nav-link {
				border: 0px;
				color: #777777;
				padding: 20px 25px;
				font-size: 14px;
			}
			
			.nav-link.active {
				border-bottom: 2px solid #01A3D6;
				font-weight: 600;
				color: #000;
				background-color: #e7f0ff61;
			}
			.nav-item {
				margin: 0;
				
				.oml-countTemp-title{
					width:18px;
					height:18px;
					background-color:#c5473b;
					border-radius:100%;
					display:flex;
					align-items:center;
					justify-content:center;
					margin-left:5px;
					color:#fff;
					font-size:10px;
				}
			}
			
			#Plan-hide___BV_tab_button__{
				display: none;
			}
			
		}

		
	}

	.inputNumbers{
			.multiselect{
				min-height:38px;

				.multiselect__tags{
					min-height:38px;

					.multiselect__placeholder{
						margin-bottom: 5px;
						padding-top:0;
					}

					.multiselect__tags-wrap{
						line-height:27px;
					}
				}

				.multiselect__tag{
					padding: 0 0 0 0;
					background: #fff;
					color:#000;
					margin-right: 0px;
					margin-bottom: 0;
					padding-right: 6px;
					position:relative;

					&:after{
						content:",";
						position:absolute;
						top:0;
						bottom:0;
						right:0;
					}

					&:last-child{
						&:after{
							content:"";
						}
					}

					i{
						display:none;
					}
				}
			}
		}
</style>